<template>
	<h1>Flash Alerts</h1>

	<div class="text-block bump-b-30">
		<pre v-highlightjs><code class="language-javascript">import flash from '@/use/FlashAlert'

export default {
	created() {
		flash('Hello, world', { blink: true })
	}
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>Options</h3>
		<pre v-highlightjs><code class="language-javascript options">{
	persistent: Boolean,	// Whether to expire the alert
	expiration: Number,		// Expiration interval in ms (default 3s)
	type: String,			// error or success (default none)
	blink: Boolean,			// Whether to blink the flash alert
	title: String,			// optional title above the text
	html: Boolean			// Whether to render message as HTML
}</code></pre>
	</div>

	<div class="text-block bump-b-30">
		<h3>How It Works</h3>
		<ul>
			<li>
				The component 'TheFlashWrap' is conditionally embedded at the root in App.vue.
			</li>
			<li>
				The list of flashes and their content is managed from the 'flash' store.
			</li>
			<li>
				Component 'TheFlashWrap' contains the animations and pulls all flash alerts from the store and lists
				them.
			</li>
			<li>
				Component 'FlashAlert' contains the individual flash styling, manages the expiration and talks to the
				store to handle the close event when you press the X.
			</li>
			<li>
				Component 'TheFlashWrap' is only included when there's a flash message in the store. In order to
				trigger the intro transition, a 'show' switch is shown when components is mounted first, then a
				separate 'active' state in the store is switched off after the last outro animation has completed.
			</li>
		</ul>
	</div>

	<div class="text-block bump-b-30">
		<h3>Demo</h3>
		<FormButton :level="1" @click="flash('Hello, world')" value="Regular" />
		<FormButton
			:level="1"
			@click="
				flash(
					`I won\'t go away unless you make me. ${'xo'.repeat(Math.round(Math.random() * 10))}${'<br>'.repeat(
						Math.ceil(Math.random() * 5)
					)}`,
					{
						persistent: true,
						html: true,
					}
				)
			"
			value="Persistent"
		/>
		<FormButton :level="1" @click="flash('You did great!', { type: 'success' })" value="Success" />
		<FormButton :level="1" @click="flash('Something is wrong.', { type: 'error' })" value="Error" />

		<br />

		<FormButton
			:level="1"
			@click="
				flash('Something is terribly wrong.', {
					type: 'error',
					blink: true,
				})
			"
			value="Blinking Error"
		/>
		<FormButton :level="1" @click="flash('The world is flat.', { title: 'Breaking News' })" value="Title" />
		<FormButton
			:level="1"
			@click="flash('<em>Display</em> some <b>HTML</b> baby!', { html: true })"
			value="HTML"
		/>
		<FormButton :level="1" @click="flash('Yo!', { expiration: 500 })" value="Custom expiration" />
	</div>
</template>

<script>
import FormButton from '@/components/FormButton'
import { injectMetaData } from '@/use/MetaData'
import flash from '@/use/FlashAlert'

export default {
	name: 'DocModulesFlash',
	components: { FormButton },
	setup() {
		injectMetaData({
			title: 'Doc: Flash Alerts',
		})
		return { flash }
	},
	methods: {
		onConfirm() {
			alert('Action confirmed')
		},
		onCancel() {
			alert('Action cancelled')
		},
	},
}
</script>

<style scoped lang="scss">
.text-block button {
	margin: 0 0.1rem 0.1rem 0;
}
</style>
